<template>
  <a-row class="content-fix" type="flex">
    <!-- {{ $route.params.id }} -->
    <!-- {{ langTab }} -->
    <!-- {{ form.id }} -->
    <spinner v-if="loading" />

    <a-col v-show="!loading" class="height100 flex-1">
      <!-- {{ form.content }} -->
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Содержимое
        </button>
        <button
          class="tab-btn"
          :class="{ active: tab === 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />Фото
        </button>
        <button
          class="tab-btn"
          :class="{ active: tab === 'document' }"
          @click="tab = 'document'"
        >
          <a-icon type="file" />Документы
        </button>
      </a-row>

      <div v-show="tab === 'content'">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="4" class="form_title"> Язык: </a-col>
          <a-col :span="18">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
              </a-button>
            </a-row>
          </a-col>
        </a-row>

        <!-- title -->
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="4" class="form_title"> Заголовок: </a-col>
          <a-col :span="20" class="sm-mb">
            <a-input
              v-model="form.title[langTab]"
              placeholder="Введите заголовок"
              class="form_title-input"
            />
            <div v-if="isTitleNull" class="error-text">
              Введите заголовок на одном из языков.
            </div>
          </a-col>
        </a-row>

        <!-- Теги -->
        <!-- <a-row align="middle" class="flex-baseline">
          <a-col :span="4" style=" padding-right: 8px">
            Теги:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-select
              v-model="form.tags"
              mode="multiple"
              placeholder="Выберите категорию"
              :filter-option="filterOption"
              show-search
              option-filter-prop="children"
              style="width: 100%"
              @change="handleChange"
            >
              <a-select-option
                v-for="t in filteredOptions"
                :key="t.id"
                :value="t.id"
              >
                {{ t.title }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row> -->
        <!-- published time -->
        <a-row class="sm-mb">
          <a-col :span="4" class="form_title"> Дата публикации: </a-col>
          <a-col :span="12">
            <input v-model="form.pub_date" type="datetime-local" />
          </a-col>
        </a-row>
        <!-- filter -->
        <a-row
          v-if="parseInt($route.params.id) === 202"
          :class="{ 'form-group--error': emptyType }"
        >
          <a-col :span="4" class="form_title"> Выберите тип: </a-col>
          <a-col :span="18">
            <a-select
              v-model="form.type"
              placeholder="Filter"
              class="table__parpage"
              option-filter-prop="children"
              style="width: 150px"
              allow-clear
            >
              <a-select-option v-for="v of filters" :key="v.id" :value="v.id">
                {{ v.title[$i18n.locale] }}
              </a-select-option>
            </a-select>
            <div v-if="isTypeNull" class="error-text">Выберите тип</div>
          </a-col>
        </a-row>
        <!-- store image modal -->
        <a-modal
          :visible="modalVisible"
          title="Хранилище изображений"
          :closable="false"
          style="max-height: 500px; max-width: 900px"
          width="80%"
        >
          <imageStorage
            @pasteHandler="editorInstance.insertContent(arguments[0])"
          />
          <template slot="footer">
            <a-button key="back" @click="modalVisible = false">
              Закрыть
            </a-button>
          </template>
        </a-modal>

        <!-- Теги -->
        <!-- <a-row align="middle" class="flex-baseline">
          <a-col :span="4" style=" padding-right: 8px">
            Выберите меню:
          </a-col>
          <a-col :span="12" class="sm-mb">
            <a-select
              v-model="form.menu"
              mode="multiple"
              placeholder="Выберите категорию"
              :filter-option="filterOption"
              show-search
              option-filter-prop="children"
              style="width: 100%"
              @change="handleChangeMenu"
            >
              <a-select-option v-for="t in menuList" :key="t.id" :value="t.id">
                {{ t.title }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row> -->

        <!-- status toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> Статус: </a-col>
          <a-switch v-model="form.is_active" />
        </a-row>
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> Поставить на главную: </a-col>
          <a-switch v-model="form.on_slider" />
        </a-row>

        <!-- editor -->
        <a-row
          :class="{ 'form-group--error': emptyContent }"
          style="margin-top: 25px"
          type="flex"
        >
          <a-col :span="4" class="form_title sm-mb">
            {{ $t("Content") }}:
          </a-col>
          <a-col v-show="langTab === 'oz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.oz"
              :initial-value="form.content.oz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.oz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'uz'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.uz"
              :initial-value="form.content.uz"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.uz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'qr'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.qr"
              :initial-value="form.content.qr"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.qr = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'ru'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.ru"
              :initial-value="form.content.ru"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.ru = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
          <a-col v-show="langTab === 'en'" :span="20">
            <!-- :key="langtab" -->
            <editor
              :value="form.content.en"
              :initial-value="form.content.en"
              :init="{
                ...tinymce.default.props,
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor
                      spoilerHandler()
                    }
                  })
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: 'Хранилище изображений',
                    onAction: () => {
                      openStoreModal()
                      editorInstance = editor
                    }
                  })
                }
              }"
              @input="form.content.en = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              Введите контент на одном из языков.
            </div>
          </a-col>
        </a-row>
      </div>

      <!-- photo tab -->
      <div v-show="tab === 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col :span="3" class="form_title"> Изображения </a-col>
          <a-col :span="12">
            <image-upload
              v-if="!imageUploading"
              :list="photoList"
              :selected-image="main_image"
              accept="image/jpeg, image/png"
              method-name="news"
              multiple
              upload-multiple
              :with-cropper="false"
              has-method
              @change="handleImages"
              @drag-update="updateFilesPosition"
              @set-main-image="activeImage"
              @delete="deleteImage"
            />
          </a-col>
        </a-row>
      </div>

      <!-- document tab  -->
      <div v-show="tab === 'document'">
        <h4 v-if="pending">Идет загрузка...</h4>

        <span v-show="!pending">
          <a-row style="margin-bottom: 15px; margin-top: 15px">
            <a-col :span="3" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button type="primary" @click="$refs.documentRef.click()">
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="document_files_array.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>
        </span>
      </div>

      <span v-if="form.id" slot="tabBarExtraContent">
        <a-popconfirm
          placement="bottomRight"
          title="Вы действительно хотите удалить данную новость?"
          @confirm="deletePost"
        >
          <a-button :disabled="pending" style="margin-right: 10px">
            <a-icon type="delete" />Удалить
          </a-button>
        </a-popconfirm>
      </span>

      <a-row
        v-if="tab === 'content'"
        class="form-buttons__action"
        style="margin-top: 20px"
        type="flex"
      >
        <a-col :span="4" style="padding-right: 8px" />

        <a-col>
          <a-button
            :disabled="pending"
            @click="
              $router.push({
                name: 'posts',
                params: { id: $route.params.id },
                query: { type: $route.query.type, title: $route.query.title }
              })
            "
          >
            <a-icon type="close" />Отмена
          </a-button>

          <!--<a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitNews((noRouterBack = true))"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить" : "Добавить" }}
          </a-button>-->

          <a-button
            :loading="pending2"
            style="margin-left: 10px"
            type="primary"
            @click="submitNews((noRouterBack = false))"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить и выйти" : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>

    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import InputFileItem from "@/components/utils/input-file-item"
import cloneDeep from "lodash/cloneDeep"

// import VueTagsInput from "@johmun/vue-tags-input"
import spoiler from "@/mixins/spoiler.js"

const newsDefaultFields = () => ({
  title: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  content: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  is_active: false,
  on_slider: false,
  is_main: false,
  images: [],
  menu: null,
  type: ""
})

export default {
  name: "News",

  components: {
    "image-upload": () => import("@/components/utils/image-upload"),
    imageStorage: () => import("@/components/imageStorage/imageStorage"),
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler"),
    editor: () => import("@tinymce/tinymce-vue"),
    InputFileItem
    // VueTagsInput
  },

  mixins: [spoiler],

  data() {
    return {
      tab: "content",
      activeFilter: "",
      langTab: $langPrefix,
      imageUploading: false,
      form: { ...newsDefaultFields() },
      fileList: [],
      pending: false,
      pending2: false,
      loading: false,
      menuList: [],

      main_image: null,
      document_file: null,
      document_files_array: [],
      autocompleteItems: [],
      emptyTitle: false,
      emptyContent: false,
      emptyType: false,

      modalVisible: false,
      tagList: [],
      selectedTags: [],
      inputTags: [],
      filters: [
        {
          id: 1,
          title: {
            oz: "Бошқалар",
            uz: "Boshqalar",
            ru: "Другие",
            en: "Others",
            qr: "Basqalar"
          }
        },
        {
          id: 3,
          title: {
            oz: "Эълонлар",
            uz: "E'lonlar",
            ru: "Объявления",
            en: "Pronouncements",
            qr: "Daǵazalar"
          }
        },
        {
          id: 7,
          title: {
            oz: "Анонслар",
            uz: "Anonslar",
            ru: "Анонсы",
            en: "Announcements",
            qr: "Jarıyalawlar"
          }
        },
        {
          id: 4,
          title: {
            oz: "Шарҳлар",
            uz: "Sharhlar",
            ru: "Обзоры",
            en: "Reviews",
            qr: "Túsindiriwler"
          }
        }
      ],
      filterByType: ""
    }
  },

  computed: {
    isTitleNull() {
      return !!(
        !this.form.title.uz &&
        !this.form.title.oz &&
        !this.form.title.ru &&
        !this.form.title.en
      )
    },
    isContentNull() {
      return !!(
        !this.form.content.uz &&
        !this.form.content.oz &&
        !this.form.content.ru &&
        !this.form.content.en
      )
    },
    isTypeNull() {
      return !this.form.type
    },
    photoList() {
      return this.form && this.form.images
    },
    getKey() {
      return this.langTab
    }
    // filteredOptions() {
    //   return this.tagList.filter((o) => !this.selectedTags.includes(o.title))
    // }
  },

  async created() {
    this.loading = true
    this.form = {
      ...newsDefaultFields()
    }
    this.form.menu = this.$route.params.id
    if (this.$route.query.type) {
      this.form.type = this.$route.query.type
    }
    this.loading = false
  },

  /*mounted() {
    this.fetchMenuList()
    this.getTagList()
  },*/

  methods: {
    handleChange(e) {
      this.form.tags = []
      this.tagList.forEach((item) => {
        e.includes(item.id) && this.form.tags.push(item.id)
      })
    },
    handleChangeMenu(e) {
      this.form.menu = []
      this.menuList.forEach((item) => {
        e.includes(item.id) && this.form.menu.push(item.id)
      })
    },
    async deletePost() {
      try {
        const res = await this.$store.dispatch(
          "posts/deletePost",
          this.$route.params && this.$route.params.id
        )
        if (res && res.status === 204) {
          // const f = this.categoryProps.news.findIndex(
          //   (elem) => elem.id === item.id
          // )

          // if (f !== -1) {
          //   this.categoryProps.news.splice(f, 1)
          // }
          await this.$router.push({
            name: "posts",
            params: { id: this.$route.query.menu }
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
    },
    async getTagList() {
      let res = await this.$api.get("/admin/post/tag/list/")
      res && res.data && (this.tagList = res.data)
    },
    async deleteImage(val) {
      await this.$api.delete("/admin/post/image/" + val + "/delete/")
    },
    openStoreModal() {
      this.modalVisible = true
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }

        if (this.form.content[from]) {
          this.form.content[to] = this[methodName](this.form.content[from])
        }
      } catch (error) {
        this.$sentry.captureMessage(error)
      }
    },
    update(newTags) {
      this.autocompleteItems = []
      this.form.tags = newTags
    },
    updateFilesPosition(files) {
      let promises = []
      // let otherImages = new FormData()
      let images_order = []
      // const mainImage = this.fileList.find(
      //   (item) => item.id && item.uzid === this.main_image
      // )
      Array.from(files)
        .filter((item) => item.id && item.uzid !== this.main_image)
        .map((item, index) => {
          // console.log("item", item)
          // console.log("index", index)
          images_order = [
            ...images_order,
            { image_id: item.id, order: index + 1 }
          ]
        })
      promises.push(
        this.$api.patch("/admin/post/image/order/update/", { images_order })
      )

      Promise.all(promises)
        .then(
          () => {},
          (err) => {
            console.log(err)
          }
        )
        .then(() => {
          this.$message.success("Позиции успешно сохранены")
        })
    },
    check2Null(arg) {
      return Object.values(arg).every((item) => !item)
    },
    validateForm() {
      if (this.check2Null(this.form.title)) {
        this.emptyTitle = true
        this.$message.error("Введите заголовок на одном из языков.")
        return false
      }
      if (parseInt(this.$route.params.id) === 202) {
        if (!this.form.type) {
          this.emptyType = true
          this.$message.error("Выберите тип")
          return false
        }
      }
      if (this.check2Null(this.form.content)) {
        this.emptyContent = true
        this.$message.error("Введите контент на одном из языков")
        return false
      }

      return true
    },
    async submitNews(noRouterBack) {
      if (!this.validateForm()) return

      if (this.pending) return

      if (noRouterBack === true) {
        this.pending = true
      } else {
        this.pending2 = true
      }

      try {
        let newData = cloneDeep(this.form)
        delete newData.attachments
        delete newData.images
        delete newData.tags

        newData = {
          ...newData,
          content_ru: newData.content.ru,
          content_uz: newData.content.uz,
          content_oz: newData.content.oz,
          content_en: newData.content.en,
          content_qr: newData.content.qr,
          title_ru: newData.title.ru,
          title_uz: newData.title.uz,
          title_oz: newData.title.oz,
          title_en: newData.title.en,
          title_qr: newData.title.qr
        }

        delete newData.content
        delete newData.title

        let f = new FormData()
        Object.keys(newData).forEach((key) => {
          if (newData[key] === null) {
            newData[key] = ""
          }
          f.append(key, newData[key])
          // newData[key] && key !== "tags" && f.append(key, newData[key])
          // key === "is_active" && f.append(key, newData[key])
          // key === "on_slider" && f.append(key, newData[key])
          // key === "tags" &&
          //   newData[key].forEach((e) => {
          //     f.append(key, +e)
          //   })
          // key === "menu" &&
          //   newData[key].forEach((e) => {
          //     f.append(key, +e)
          //   })
        })

        // Set images
        this.fileList.forEach((item) => {
          f.append(
            "images",
            item && item.fileTarget,
            item && item.fileTarget && item.fileTarget.name
          )
        })
        // Set attachments
        this.document_files_array.forEach((item) => {
          item &&
            item.binary_file &&
            !item.binary_file.id &&
            f.append("attachments", item.binary_file, item.binary_file.name)
        })

        /*for (let pair of f.entries()) {
          console.log(pair[0] + " " + pair[1])
        }*/

        await this.$api.post("/admin/post/create/", f).then(() => {
          this.$message.success("Новость успешно сохранено")
        })

        if (noRouterBack === true) {
          this.pending = false
        } else {
          this.pending2 = false
        }

        if (!noRouterBack) {
          await this.$router.push({
            name: "posts",
            params: { id: this.$route.params.id },
            query: {
              type: this.$route.query.type,
              title: this.$route.query.title
            }
          })
        }
      } catch (error) {
        console.error(error)
        this.$message.error(
          "Возникла проблема при " +
            (this.form && this.form.id ? "сохранении" : "добавлении") +
            " новости" +
            "\r\n\r\n" +
            error
        )

        this.$sentry.captureMessage(error)
      }
    },

    async handleImages(arg) {
      this.fileList = arg
    },
    fetchMenuList() {
      this.$store.dispatch("menu/fetch").then((res) => {
        this.menuList = res
      })
    },
    activeImage(e) {
      this.photoList.forEach((item, index) => {
        this.photoList[index].is_active = item.id === e
      })
      this.$api.patch("/admin/post/image/" + e + "/update/")
    },
    async deleteDocumentFileFromState(arg) {
      if (this.form.id) {
        try {
          const res = await this.$store.dispatch(
            "file/delete",
            arg.binary_file.id
          )

          if (res) {
            const f = this.document_files_array.findIndex(
              (item) => item.uid === arg.uid
            )

            if (f !== -1) {
              this.document_files_array = [
                ...this.document_files_array.slice(0, f),
                ...this.document_files_array.slice(f + 1)
              ]
            }
          }
        } catch (err) {
          this.$sentry.captureMessage(err)
        }
      } else {
        this.document_files_array = this.document_files_array.filter(
          (el) => el.uid !== arg.uid
        )
      }
    },
    async handleDocumentInput(e) {
      if (this.form.id) {
        if (e.target.files && e.target.files !== 0) {
          let formImage = {
            post: this.$route.params.id
          }
          let f = new FormData()
          Object.keys(formImage).forEach((k) => {
            f.append(k, formImage[k])
          })
          Array.from(e.target.files).forEach((item) => {
            f.append("file", item)
          })
          let res = await this.$api.post("/admin/post/file/upload/", f)
          if (res && res.data) {
            this.document_files_array = []
            res.data.attachments.forEach((item) => {
              this.document_files_array = [
                ...this.document_files_array,
                {
                  binary_file: item,
                  uid: Math.random().toString().slice(2)
                }
              ]
            })
          }
        }
      } else {
        if (e.target.files && e.target.files !== 0) {
          Array.from(e.target.files).forEach((item) => {
            this.document_files_array = [
              ...this.document_files_array,
              {
                binary_file: item,
                uid: Math.random().toString().slice(2)
              }
            ]
          })
          // console.log(this.document_files_array)
        }
      }
      this.$refs.documentRef.value = null
    }
  }
}
</script>
<style>
.ant-modal {
  max-height: 600px;
}
.scroll-container {
  padding: 20px 0;
}
input[type="datetime-local"] {
  border-radius: 2px;
  padding: 4px;
  border: 1px solid #c7c7c7;
}
.flex-end {
  justify-content: flex-end;
}
</style>
